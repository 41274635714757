@mixin heading-1 {
  @include secondary-text(20px, center);
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.26px;
}

@mixin heading-2 {
  @include secondary-text(18px);
  line-height: 1.5;
  letter-spacing: 0.23px;
  font-weight: 500;
}

@mixin heading-3 {
  @include primary-text(20px);
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.26px;
}

@mixin menu-text {
  @include secondary-text(14px);
  line-height: 1.43;
}

@mixin sidebar-text {
  @include secondary-text(12px);
  line-height: 1.67;
  letter-spacing: normal;
}

@mixin text-style {
  @include primary-text(14px);
  line-height: 1.21;
  letter-spacing: 0.24px;
}

@mixin text-field-active {
  @include primary-text(16px);
  line-height: 1.19;
  letter-spacing: 0.49px;
}

@mixin text-field-sub {
  @include primary-text(14px, $color: var(--grey-alpha-75));
  line-height: 1.21;
  letter-spacing: 0.24px;
}

@mixin overline {
  @include primary-text(10px, $color: var(--grey-alpha-60));
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 1.56px;
  text-transform: uppercase;
}

@mixin primary-button-text {
  @include secondary-text(14px, center, var(--primary-color));
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1.25px;
}

@mixin warn-button-text {
  @include secondary-text(14px, center, var(--warn-color));
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1.25px;
}

@mixin secondary-text($size: 12px, $align: left, $color: var(--dark-grey)) {
  color: $color;
  font-family: Poppins;
  font-size: $size;
  text-align: $align;
}

@mixin primary-text($size: 12px, $align: left, $color: var(--dark-grey)) {
  color: $color;
  font-family: Roboto;
  font-size: $size;
  text-align: $align;
}
