@use '@angular/material' as mat;
@use 'layout';
@import 'typography';

button.mat-mdc-button-base,
a.mat-mdc-button-base {
  display: flex;
  margin: 0;
  &:not(.no-uppercase) {
    text-transform: uppercase;
  }
  &:not([mat-icon-button]) {
    border-radius: 50px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:not([mat-mini-fab]) {
      padding: 0 16px;
    }
    &.big {
      padding: 4px 16.1px;
    }
    &.small {
      font-size: 12px;
      line-height: 30px;
      padding: 0px 8px;
    }
  }
  &:is([mat-icon-button]) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    margin: unset;
  }
  &.default-text {
    color: var(--dark-grey);
  }
  &.text-white {
    color: var(--white);
  }
}

div[mat-card-avatar].mat-mdc-card-avatar {
  margin-bottom: 0px;
}

button.mat-mdc-outlined-button,
button.mat-mdc-raised-button,
button.mat-mdc-icon-button,
a.mat-mdc-icon-button {
  display: flex;
  padding: 0;
  min-height: max-content;
  .mat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
  }
  &.fit-icon {
    width: 24px;
    height: 24px;
    line-height: normal;
    mat-icon {
      margin-top: -1px;
    }
  }
  &.small {
    width: 32px;
    height: 32px;
    line-height: 30px;
    mat-icon {
      transform: scale(0.8);
    }
  }
  &.extra-small {
    width: 24px;
    height: 24px;
    padding: 2px;
    line-height: 15px;
    mat-icon {
      transform: scale(0.7);
      margin-top: -2px;
      margin-left: -2px;
    }
  }
  &.outlined {
    border: 1px solid var(--dark-grey);
    &[disabled] {
      border-color: var(--grey-alpha-25);
    }
    &.mat-primary:not([disabled]) {
      border-color: var(--primary-color);
    }
    &.mat-accent:not([disabled]) {
      border-color: var(--accent-color);
    }
  }
}

mat-mdc-button-toggle-group.gc-button-toggle {
  display: flex;
  flex-direction: row;
  > mat-button-toggle {
    flex: 1;
  }
  .mat-mdc-button-toggle-label-content {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
  }
  border-radius: 8px;
  &.primary {
    border: 2px solid var(--primary-color);
    > mat-mdc-button-toggle {
      color: var(--primary-color);
      &.mat-mdc-button-toggle-checked {
        background-color: var(--primary-color);
        color: var(--white);
      }
    }
  }

  &.accent {
    border: 2px solid var(--primary-color);
    > mat-mdc-button-toggle {
      color: var(--primary-color);
      &.mat-mdc-button-toggle-checked {
        background-color: var(--primary-color);
        color: var(--white);
      }
    }
  }
}

mat-chip-list.gc-chip-list {
  height: 36px;
  > .mat-chip-list-wrapper {
    > mat-chip {
      height: 36px;
      text-align: center;
      display: block;
    }
  }
  &.accent {
    mat-chip {
      &:not(.mat-chip-selected) {
        background-color: unset;
      }
      border: 1px solid var(--primary-color);
    }
  }
  &.primary {
    mat-chip {
      background-color: unset !important;
      border: 1px solid var(--primary-color);
    }
  }
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
  background-color: var(--accent-color);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track {
  background-color: var(--accent-color);
  opacity: 0.5;
}

button.mat-mdc-stroked-button,
a.mat-mdc-stroked-button {
  &.mat-primary:not([disabled]) {
    border-color: var(--primary-color) !important;
  }
  &.mat-accent:not([disabled]) {
    border-color: var(--accent-color) !important;
  }
  &.mat-warn:not([disabled]) {
    border-color: var(--warn-color) !important;
  }
  &.mat-error:not([disabled]) {
    border-color: var(--error-color) !important;
  }
}

mat-mdc-horizontal-stepper {
  &.no-label {
    div.mat-mdc-step-label {
      display: none;
    }
  }
  &.header-only {
    > .mat-mdc-horizontal-content-container {
      display: none;
    }
  }
  &.done-accent .mat-mdc-step-icon.mat-step-icon-state-done {
    background-color: var(--accent-color);
    color: var(--dark-grey);
  }
}

mat-tab-group.no-header {
  > mat-tab-header {
    display: none;
  }
}

.mat-tab-group.custom-tab-group {
  &.rounded-bottom {
    > .mat-tab-header {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
  &.rounded-top {
    > .mat-tab-header {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
  > .mat-tab-header {
    @include mat.elevation(4);
    z-index: 10;
  }

  &.fill-height {
    > .mat-tab-body-wrapper {
      height: 100%;
    }
  }

  &.fill-height {
    > .mat-tab-body-wrapper {
      height: 100%;
    }
  }

  &.text-white {
    color: var(--white);
  }

  .mat-tab-body-wrapper {
    color: var(--dark-grey);
  }

  .mat-tab-label {
    @include secondary-text(16px, $color: inherit);
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 1.5;
    text-transform: uppercase;
  }

  .mat-tab-label.mat-tab-label-active {
    opacity: 1;
  }
}

.mat-tab-nav-bar.custom-nav-bar {
  color: var(--primary-color);

  &.rounded-bottom {
    &.mat-tab-header {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  &.rounded-top {
    &.mat-tab-header {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  &.mat-tab-header {
    @include mat.elevation(4);
    z-index: 10;
  }

  &.fill-height {
    > .mat-tab-body-wrapper {
      height: 100%;
    }
  }

  &.text-white {
    color: var(--white);
  }

  .mat-tab-body-wrapper {
    color: var(--dark-grey);
  }

  .mat-tab-link {
    @include secondary-text(16px, $color: inherit);
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 1.5;
    text-transform: uppercase;
  }

  .mat-tab-link.mat-tab-label-active {
    opacity: 1;
  }
}

mat-form-field.otp-box {
  div.mat-mdc-form-field-infix {
    border-top: 0px;
    font-size: 20px;
    text-align: center;
  }
}

.mat-icon-button.social-button {
  width: 36px;
  height: 36px;

  mat-icon {
    height: 18px;
    width: 18px;
    margin-top: -8px;
  }
}

// Mat Menu Font and Icons color
.mat-menu-panel {
  .mat-icon {
    color: var(--primary-color);
  }
  .mat-menu-item {
    @include secondary-text(14px);
    line-height: 1.43;
  }
}

// Small Mat Badge
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -2px;
  top: -2px;
  height: 12px;
  width: 12px;
}

.gc-spread-button-toggle {
  border: 0;
  justify-content: space-around;
  flex-wrap: wrap;
  height: fit-content;
  // Base button Style
  .mat-button-toggle {
    // Button Text
    .mat-button-toggle-label-content {
      line-height: 100% !important;
      padding: 4px 8px 8px 2px !important;
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: center space-around;
      align-items: center;
      @include text-style;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: var(--primary-color);
    }
    border-radius: 16px;
    width: fit-content;
    margin-bottom: 10px;
    margin-right: 4px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white);
    border: solid 1px var(--primary-color);
  }

  // Consecutive Buttons
  .mat-button-toggle + .mat-button-toggle {
    border: solid 1px var(--dark-grey);
    border: solid 1px var(--primary-color);
  }

  // Checked Buttons
  .mat-button-toggle-checked {
    background-color: var(--primary-color);
    .mat-button-toggle-label-content {
      color: var(--white);
    }
  }
}

.gc-about-dialog {
  width: 50%;
  min-width: 280px;
  max-width: 500px;
  mat-dialog-container {
    padding: 12px;
  }
}

.tabs-spacing .mat-tab-body-wrapper {
  margin-top: 20px;
}

.tooltip-primary {
  .mdc-tooltip__surface {
    @include mat.elevation(3);
    @include primary-text(11px, $color: var(--white));
    background-color: var(--primary-color);
  }
}

.tooltip-warning {
  .mdc-tooltip__surface {
    @include mat.elevation(3);
    @include primary-text(11px, $color: var(--white));
    background-color: var(--warning-orange);
  }
}

.mat-paginator.bottom {
  @include mat.elevation(3);
  color: var(--dark-grey);
  border-radius: 0 0 20px 20px;
  font-size: 12px;
  .mat-paginator-range-label {
    margin: 0 0 0 12px;
  }
  .mat-paginator-page-size-label {
    margin: 0;
  }
  .mat-paginator-page-size-select {
    margin: 0;
  }
  @media (min-width: layout.$sm) {
    font-size: 14px;
  }
}

.settings-header {
  .mat-card-header-text {
    margin: 0px;
  }
}

:root .mat-expansion-panel-body {
  padding: 0 14px 16px;

  @media (max-width: layout.$sm) {
    padding: 0 8px 10px;
  }

  @media (max-width: layout.$xs) {
    padding: 0 2px 4px;
  }
}

.terms-conditions-dialog .mat-mdc-dialog-surface {
  height: unset;
  padding: 12px;
  border-radius: 15px;
  overflow-x: hidden;
}

// Style timepicker toggle to match datepicker
ngx-material-timepicker-toggle {
  > button > svg {
    width: 1.5em;
    height: 1.5em;
    fill: var(--grey-alpha-50);
  }
}

mat-checkbox.terms-btn {
  label.mat-checkbox-layout {
    text-align: center;
    white-space: normal !important;
    @media (max-width: 555px) {
      padding: 0px 10px;
    }
    @media (max-width: 340px) {
      padding: 0;
    }
  }
}

mat-form-field.multiline-error {
  .mat-form-field-underline {
    bottom: unset;
  }
  .mat-form-field-subscript-wrapper {
    position: relative;

    .mat-error > * {
      margin-top: 0;
      margin-bottom: 4px;
    }
  }
}
