$topbar-height: 80px;
$topbar-height-lt-md: 56px;

// Angular Flex layout
// https://github.com/angular/flex-layout/blob/master/docs/documentation/Responsive-API.md
$xs: 37.5rem; // @media (min-width: 600px)
$xsm: 48rem; // @media (min-width: 768px)
$sm: 60rem; // @media (min-width: 960px)
$smd: 62rem; // @media (min-width: 992px)
$md: 80rem; // @media (min-width: 1280px)
$lg: 120rem; // @media (min-width: 1920px)

/*
https://www.w3schools.com/css/css_rwd_mediaqueries.asp

-Extra small devices (phones, 600px and down)-
@media only screen and (max-width: 600px) {...}

-Small devices (portrait tablets and large phones, 600px and up)-
@media only screen and (min-width: 600px) {...}

-Medium devices (landscape tablets, 768px and up)-
@media only screen and (min-width: 768px) {...}

-Large devices (laptops/desktops, 992px and up)-
@media only screen and (min-width: 992px) {...}

-Extra large devices (large laptops and desktops, 1200px and up)-
@media only screen and (min-width: 1200px) {...}
*/
