@use '@angular/material' as mat;
@use 'sass:color';
@import 'typography';
@import 'colours';
@include mat.core();

// Fonts
$fontConfig: (
  display-4: mat.m2-define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat.m2-define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.m2-define-typography-level(45px, 48px, 400, 'Roboto', 0em),
  display-1: mat.m2-define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.m2-define-typography-level(24px, 32px, 400, 'Roboto', 0em),
  title: mat.m2-define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat.m2-define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.m2-define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.m2-define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.m2-define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.m2-define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.m2-define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.m2-define-typography-level(inherit, 1.125, 400, 'Roboto', 0.0893px),
);

// Theme Config
$dark-primary-text: rgba($dark-grey, 1);
$light-primary-text: $white;

$mat-primary: (
  main: var(--primary-color),
  lighter: var(--primary-lighter-color),
  darker: var(--primary-darker-color),
  200: var(--primary-color),
  contrast: (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  ),
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: var(--accent-color),
  lighter: var(--accent-lighter-color),
  darker: var(--accent-darker-color),
  200: var(--accent-color),
  contrast: (
    main: rgba(black, 0.87),
    lighter: rgba(black, 0.87),
    darker: rgba(black, 0.87),
  ),
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

:root {
  --accent-color: #{$accent-color};
  --accent-darker-color: #{$accent-darker-color};
  --accent-lighter-color: #{$accent-lighter-color};
  --background-color: #{$light-grey};
  background-color: var(--background-color);
  --black: #{$black};
  --dark-grey: #{$dark-grey};
  --error-red: #{$error-red};
  --grey: #{$grey};
  --grey-alpha-25: #{color.change($dark-grey, $alpha: 0.25)};
  --grey-alpha-50: #{color.change($dark-grey, $alpha: 0.5)};
  --grey-alpha-60: #{color.change($dark-grey, $alpha: 0.6)};
  --grey-alpha-75: #{color.change($dark-grey, $alpha: 0.75)};
  --light-grey: #{$light-grey};
  --pdf-viewer-background: #{$pdf-viewer-background};
  --primary-color: #{$primary-color};
  --primary-darker-color: #{$primary-darker-color};
  --primary-lighter-color: #{$primary-lighter-color};
  --primary-transparent: #{$primary-transparent};
  --text-accent-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{rgba(black, 0.87)};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --tertiary-blue: #{$tertiary-blue};
  --text-primary-color: #{white};
  --text-primary-darker-color: #{white};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-warn-color: #{white};
  --text-warn-darker-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --warning-orange: #{$warning-orange};
  --warn-color: #{$error-red};
  --warn-darker-color: #{$error-red-900};
  --warn-lighter-color: #{$error-red-200};
  --white: #{$white};
  --white-alpha-4: #{color.change($white, $alpha: 0.04)};
  --white-alpha-6: #{color.change($white, $alpha: 0.06)};
  --white-alpha-40: #{color.change($white, $alpha: 0.4)};

  div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: var(--background-color);
    border-radius: 8px;
  }

  div::-webkit-scrollbar {
    width: 10px;
    background-color: var(--background-color);
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: var(--primary-color);
  }

  // When setting the theme it throws away the opacity so we need to set it again
  .mat-mdc-progress-bar {
    --mdc-linear-progress-track-color: rgba(var(--primary-color), 0.5);
  }
}

$mat-warn: (
  main: $error-red,
  lighter: $error-red-200,
  darker: $error-red-900,
  200: $error-red,
  contrast: (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  ),
);

$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfig,
    density: 0,
  )
);

// overwrite all the base mdc theme with the custom theme
@include mat.all-component-themes($theme);
