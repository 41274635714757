@import 'typography';

html,
body {
  min-height: 100%;
  @include text-style;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-overline {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 0.625rem;
  letter-spacing: 0.09375rem;
}

.uppercase {
  text-transform: uppercase;
}

.gc-dialog-no-padding .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}

.gc-dialog .mat-dialog-content {
  max-height: unset;
}

.heading-1 {
  @include heading-1;
}

.heading-2 {
  @include heading-2;
}

.heading-3 {
  @include heading-3;
}

.overline {
  @include overline;
}

.cdk-overlay-backdrop.blur {
  backdrop-filter: blur(3px) brightness(50%);
}

.cdk-overlay-backdrop.light-blur {
  backdrop-filter: blur(3px);
  background-color: var(--white-alpha-40);
}

.sidebar-text {
  @include sidebar-text;
}

.mygc-text {
  font-weight: bold;
  font-style: italic;
}

.emphasize {
  font-weight: bold;
}

h2 {
  @include heading-2;
}

.overflow-auto {
  height: 100%;
  overflow: auto;
}

.primary-color {
  color: var(--primary-color);
}

.login-splash-register .mat-card {
  height: 658px;
}

.login-splash-password .mat-card {
  height: 550px;
}

.remote-video video {
  max-height: 100%;
  max-width: 100%;
}

.mat-text-field {
  .mat-form-field-infix {
    padding: 0.8em 0 0.75em 0 !important;
  }
}

.mat-action-row {
  padding: 16px 24px;
}

.image-innerHtml {
  img {
    object-fit: contain;
    max-width: 100%;
    height: auto;
  }
}

.booking-success {
  margin: 8px;
}

.width-100 {
  width: 100%;
}
